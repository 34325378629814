@use 'sass:map';
@use '@angular/material' as mat;

@mixin _matButtonLoading($backgroundColor, $foregroundColor) {
  &.mat-loading {
    &.mat-mdc-unelevated-button:disabled,
    &.mat-mdc-unelevated-button[disabled] {
      background-color: $backgroundColor;
    }

    .mdc-button__label {
      visibility: hidden;
    }

    .mat-mdc-progress-spinner {
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);

      .mdc-circular-progress__determinate-circle,
      .mdc-circular-progress__indeterminate-circle-graphic {
        stroke: $foregroundColor;
      }
    }

    &.mat-mdc-outlined-button {
      .mat-mdc-progress-spinner {
        .mdc-circular-progress__determinate-circle,
        .mdc-circular-progress__indeterminate-circle-graphic {
          stroke: $backgroundColor;
        }
      }
    }
  }
}

@mixin theme($theme-or-config) {
  $typography: mat.m2-define-typography-config(
    $button: mat.m2-define-typography-level(14px, 14px, 500, 'Roboto', 'normal'),
  );

  @include mat.button-typography($typography);
  $palette: map.get($theme-or-config, 'primary');
  $backgroundColor: mat.m2-get-color-from-palette($palette, 500);
  $foregroundColor: mat.m2-get-contrast-color-from-palette($palette, 500);

  .mat-mdc-button-base {
    @include _matButtonLoading($backgroundColor, $foregroundColor);

    &.btn-action {
      min-width: 7rem;
      height: 3rem;
    }

    &.mat-mdc-outlined-button:not(:disabled) {
      border-color: currentColor;
    }

    &.with-tr-icon {
      .mdc-button__label {
        display: flex;
        gap: 0.25rem;
      }
    }

    &.btn-success {
      --mdc-filled-button-container-color: var(--tr-green);
    }

    &.btn-danger {
      --mdc-filled-button-container-color: var(--tr-red);
    }

    &.btn-warning {
      --mdc-filled-button-container-color: var(--tr-orange);
    }
  }

  .mat-mdc-button-base.mat-unthemed {
    $palette: map.get($theme-or-config, 'primary');
    $backgroundColor: white;
    $foregroundColor: var(--mat-icon-color);

    @include _matButtonLoading($backgroundColor, $foregroundColor);
  }

  .mat-mdc-button-base.mat-primary {
    $palette: map.get($theme-or-config, 'primary');
    $backgroundColor: mat.m2-get-color-from-palette($palette, 500);
    $foregroundColor: mat.m2-get-contrast-color-from-palette($palette, 500);

    @include _matButtonLoading($backgroundColor, $foregroundColor);
  }

  .mat-mdc-button-base.mat-accent {
    $palette: map.get($theme-or-config, 'accent');
    $backgroundColor: mat.m2-get-color-from-palette($palette, 500);
    $foregroundColor: mat.m2-get-contrast-color-from-palette($palette, 500);

    @include _matButtonLoading($backgroundColor, $foregroundColor);
  }

  .mat-mdc-button-base.mat-warn {
    $palette: map.get($theme-or-config, 'warn');
    $backgroundColor: mat.m2-get-color-from-palette($palette, 500);
    $foregroundColor: mat.m2-get-contrast-color-from-palette($palette, 500);

    @include _matButtonLoading($backgroundColor, $foregroundColor);
  }
}
