@use 'src/assets/scss/utils/colors';
@use 'src/assets/scss/utils/mixins';
@use 'ag-grid-community/styles/ag-grid.css';
@use 'ag-grid-community/styles/ag-theme-material.css';

.ag-theme-material {
  --ag-font-size: 0.875rem;
  --ag-font-family: roboto, sans-serif;

  --ag-material-primary-color: var(--tr-primary-color);
  --ag-material-accent-color: var(--tr-accent-color);
  --ag-material-warn-color: var(--tr-warn-color);
  --ag-header-foreground-color: var(--tr-primary-color);
  --ag-selected-row-background-color: var(--tr-gray-blue);

  //--ag-card-radius: 10px;
  //--ag-card-shadow: 0 10px 40px rgb(83, 0, 106);
  //--ag-popup-shadow: var(--ag-card-shadow);

  .ag-header-cell {
    font-size: 1rem;
  }

  &.ag-secondary-header {
    --ag-header-background-color: var(--tr-light-blue);
    --ag-header-cell-hover-background-color: var(--tr-light-blue);
  }

  &.ag-no-min-height {
    .ag-center-cols-viewport {
      min-height: initial !important;
    }
  }
}

.ag-hide-body .ag-body {
  display: none;
}

.ag-cell.cell-no-outline {
  --ag-range-selection-border-color: transparent;
}

.ag-right-aligned-cell {
  .ag-cell-value {
    text-align: right;
  }

  .ag-header-cell-label {
    justify-content: flex-end;
  }
}

.ag-centered-cell {
  .ag-cell-value {
    text-align: center;
  }

  .ag-header-cell-label {
    justify-content: center;
  }
}

.ag-cell-highlighted {
  background-color: #f2f6ff;
}

.ag-row-hover {
  .ag-cell-highlighted {
    background-color: #eef2fd;
  }
}

.ag-row.ag-row-disabled {
  color: rgba(0, 0, 0, 0.2);

  & > .ag-cell-value {
    color: inherit !important;
  }
}

.ag-row-no-border {
  --ag-row-border-width: 0px;

  .ag-floating-top,
  .ag-floating-bottom {
    border-width: 0;
  }
}

.ag-separated-header {
  .ag-root-wrapper {
    background-color: transparent;

    .ag-header {
      background-color: var(--ag-header-background-color);
      border-bottom-width: 0;
    }

    .ag-body,
    .ag-sticky-top,
    .ag-floating-top,
    .ag-floating-bottom {
      background-color: var(--ag-background-color);
    }

    .ag-root {
      row-gap: 20px;
    }
  }
}

.ag-highlighted-header {
  --ag-header-cell-hover-background-color: var(--tr-gray-blue);

  .ag-root-wrapper {
    .ag-header-cell {
      background-color: var(--tr-gray-blue);
    }
  }
}

.ag-row-secondary {
  --ag-background-color: var(--tr-light-blue);
  --ag-odd-row-background-color: var(--tr-light-blue);
}

.ag-box-shadow {
  &:not(.ag-separated-header) {
    @include mixins.soft-box-shadow;
  }

  &.ag-separated-header {
    .ag-body,
    .ag-header {
      @include mixins.soft-box-shadow;
    }
  }
}

.ag-row-warning {
  .ag-cell {
    background-color: color-mix(in srgb, var(--tr-warn-color), #fff 90%);
  }
}

.p-0 {
  padding: 0;
}

.ag-insert-row {
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.3s ease-in-out;

  .ag-insert-row-button {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 0.2rem;
    background-color: var(--tr-primary-color);
    color: var(--tr-white);
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding: 0;
  }

  .ag-insert-row-border {
    width: 100%;
    height: 2px;
    background-color: var(--tr-primary-color);
  }

  &.ag-insert-row-hover {
    opacity: 1;
  }
}
