.files {
  grid-gap: 1rem;

  .mat-mdc-list {
    min-width: 0;
  }

  .mdc-list {
    align-self: start;
    box-shadow: none;
    padding: 0;
  }

  .mdc-list-item {
    font-size: 14px !important;
    height: 46px !important;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    margin-bottom: 12px;
  }

  .mdc-icon-button {
    width: 22px;
    height: 22px;
    padding: 0;

    &:hover .mat-mdc-button-persistent-ripple::before {
      opacity: 0;
    }
  }

  mat-icon {
    transform: scale(0.7);
  }
}

.file-item {
  font-size: 14px;
  .actions {
    mat-icon + mat-icon {
      margin-left: 1rem;
    }
  }
  .text {
    margin-left: 3px;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.pdf-file-viewer .mat-mdc-dialog-container {
  position: relative;
  padding: 0 !important;
  overflow-y: hidden;

  .close-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    display: flex;
    z-index: 99;
  }
}
