$icomoon-font-family: "Trigon-icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-trigon-logo: unquote('"\\e900"');
$icon-costpocket: unquote('"\\e901"');
$icon-dashboard: unquote('"\\e902"');
$icon-sales: unquote('"\\e905"');
$icon-expenses: unquote('"\\e90e"');
$icon-accounting: unquote('"\\e906"');
$icon-shopping-card: unquote('"\\e904"');
$icon-user: unquote('"\\e951"');
$icon-bureaus: unquote('"\\e908"');
$icon-document: unquote('"\\e90a"');
$icon-reports: unquote('"\\e903"');
$icon-upload: unquote('"\\e90b"');
$icon-download: unquote('"\\e90c"');
$icon-list-items: unquote('"\\e90f"');
$icon-car: unquote('"\\e910"');
$icon-lock: unquote('"\\e911"');
$icon-lock-open: unquote('"\\e912"');
$icon-settings: unquote('"\\e914"');
$icon-transfer: unquote('"\\e916"');
$icon-visibility: unquote('"\\e917"');
$icon-grid: unquote('"\\e91a"');
$icon-rotate: unquote('"\\e918"');
$icon-plus: unquote('"\\e944"');
$icon-add: unquote('"\\e919"');
$icon-plus-big: unquote('"\\e913"');
$icon-caret-circle: unquote('"\\e91b"');
$icon-caret-down: unquote('"\\e91c"');
$icon-caret-up: unquote('"\\e91d"');
$icon-caret-left: unquote('"\\e91e"');
$icon-caret-right: unquote('"\\e91f"');
$icon-arrow-left: unquote('"\\e920"');
$icon-arrow-right: unquote('"\\e921"');
$icon-arrow-up: unquote('"\\e922"');
$icon-arrow-bottom: unquote('"\\e923"');
$icon-bookmark-filled: unquote('"\\e924"');
$icon-bookmark: unquote('"\\e925"');
$icon-tools: unquote('"\\e927"');
$icon-reload: unquote('"\\e928"');
$icon-checkbox: unquote('"\\e929"');
$icon-checkbox-unchecked: unquote('"\\e92a"');
$icon-checkbox-indeterminate: unquote('"\\e92b"');
$icon-check: unquote('"\\e92c"');
$icon-close: unquote('"\\e930"');
$icon-check-circle: unquote('"\\e92d"');
$icon-check-circle-outline: unquote('"\\e92e"');
$icon-close-circle: unquote('"\\e92f"');
$icon-duplicate: unquote('"\\e931"');
$icon-create: unquote('"\\e932"');
$icon-pencil: unquote('"\\e90d"');
$icon-filter: unquote('"\\e95c"');
$icon-filter-filled: unquote('"\\e95d"');
$icon-attachment: unquote('"\\e907"');
$icon-email: unquote('"\\e934"');
$icon-error: unquote('"\\e935"');
$icon-error-outline: unquote('"\\e936"');
$icon-round-report: unquote('"\\e948"');
$icon-actions: unquote('"\\e926"');
$icon-menu: unquote('"\\e93c"');
$icon-filter-list: unquote('"\\e938"');
$icon-dots-horizontal: unquote('"\\e93e"');
$icon-dots-vertical: unquote('"\\e93f"');
$icon-fullscreen: unquote('"\\e939"');
$icon-fullscreen-exit: unquote('"\\e93a"');
$icon-minus-circle: unquote('"\\e93d"');
$icon-notification-important: unquote('"\\e940"');
$icon-notifications-filled: unquote('"\\e941"');
$icon-notifications: unquote('"\\e942"');
$icon-radio-checked: unquote('"\\e946"');
$icon-radio-unchecked: unquote('"\\e947"');
$icon-search: unquote('"\\e949"');
$icon-star: unquote('"\\e94b"');
$icon-star-empty: unquote('"\\e94c"');
$icon-star-half: unquote('"\\e94d"');
$icon-favorite: unquote('"\\e937"');
$icon-transactions-2: unquote('"\\e94e"');
$icon-upload-to-cloud: unquote('"\\e94f"');
$icon-user-transfer: unquote('"\\e93b"');
$icon-user-group: unquote('"\\e950"');
$icon-pop-out: unquote('"\\e945"');
$icon-place: unquote('"\\e943"');
$icon-share: unquote('"\\e94a"');
$icon-dns: unquote('"\\e909"');
$icon-rule: unquote('"\\e915"');
$icon-account_tree: unquote('"\\e933"');
$icon-euro: unquote('"\\e952"');
$icon-exit: unquote('"\\e953"');
$icon-group-people: unquote('"\\e954"');
$icon-local-mall: unquote('"\\e955"');
$icon-globe: unquote('"\\e956"');
$icon-pause-circle: unquote('"\\e957"');
$icon-reload-circle: unquote('"\\e958"');
$icon-annual-reports: unquote('"\\e959"');
$icon-e-invoice: unquote('"\\e95a"');
$icon-uku: unquote('"\\e95b"');
$icon-law-paper: unquote('"\\e95e"');

