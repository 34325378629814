.mat-mdc-checkbox {
  &.ng-warning {
    --mat-checkbox-label-text-color: var(--tr-orange);
    --mdc-checkbox-selected-hover-icon-color: var(--tr-orange);
    --mdc-checkbox-selected-focus-icon-color: var(--tr-orange);
    --mdc-checkbox-selected-icon-color: var(--tr-orange);
    --mdc-checkbox-unselected-icon-color: var(--tr-orange);
    --mdc-checkbox-unselected-hover-icon-color: var(--tr-orange);
    --mdc-checkbox-unselected-focus-icon-color: var(--tr-orange);
  }
}
