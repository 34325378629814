quill-editor {
  width: 100%;

  &.readonly,
  &[format='text'] {
    .ql-toolbar .ql-formats {
      pointer-events: none;
      opacity: 0.3;
    }
  }

  &.readonly {
    .ql-toolbar,
    .ql-container {
      pointer-events: none;
      background-color: var(--tr-light-gray);
    }
  }

  &.ng-touched.ng-invalid {
    .ql-toolbar {
      border-top-color: var(--tr-red);
      border-left-color: var(--tr-red);
      border-right-color: var(--tr-red);
    }
    .ql-container {
      border-color: var(--tr-red);
    }
  }

  &.large {
    .ql-container {
      height: auto;
      border-radius: 0 0 4px 4px;

      .ql-editor {
        min-height: 220px;
      }
    }
  }

  .ql-editor {
    color: var(--mdc-outlined-text-field-input-text-color);

    p {
      margin: 0;
      line-height: 24px;
    }

    &.ql-blank {
      &:before {
        top: 14px;
        font-style: normal;
        letter-spacing: var(--mdc-outlined-text-field-label-text-tracking);
        font-size: var(--mdc-outlined-text-field-label-text-size);
        color: var(--mdc-outlined-text-field-label-text-color);
      }
    }
  }

  .ql-container {
    border-radius: 4px;
  }

  .ql-toolbar {
    border-radius: 4px 4px 0 0;
  }

  .ql-mention-list-container {
    max-height: 300px;
    overflow-y: auto;
    padding: 12px 0;
  }

  .ql-mention-list-item {
    line-height: 30px;
  }

  .ql-mention-list-container {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
    overflow: auto;
    width: 270px;
    z-index: 9001;
  }

  .ql-mention-loading {
    font-size: 16px;
    line-height: 44px;
    padding: 0 20px;
    vertical-align: middle;
  }

  .ql-mention-list {
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
  }

  .ql-mention-list-item {
    cursor: pointer;
    font-size: 16px;
    line-height: 44px;
    padding: 0 20px;
    vertical-align: middle;

    &.disabled {
      cursor: auto;
    }

    &.selected {
      background-color: #d3e1eb;
      text-decoration: none;
    }
  }

  .mention {
    background-color: #d3e1eb;
    border-radius: 6px;
    height: 24px;
    margin-right: 2px;
    padding: 3px 0;
    user-select: all;
    width: 65px;

    > span {
      margin: 0 3px;
    }
  }
}
