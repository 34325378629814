@use 'src/assets/scss/utils/mixins';

html,
body {
  height: 100%;
}

html {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: var(--tr-body-bg);
}

router-outlet {
  display: none;
}

.no-resize {
  resize: none;
}

.no-overflow {
  overflow: hidden;
}

.print-only {
  display: none !important;
}

.soft-box-shadow {
  @include mixins.soft-box-shadow;
}

.pulse {
  @include mixins.pulse;
}
