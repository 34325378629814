.tr-label {
  --label-color: var(--tr-mad-gray);
  --label-bg-color: rgba(30, 29, 43, 0.1);
  --label-light-bg-color: rgba(167, 166, 174, 0.1);

  display: inline-flex;
  align-items: center;
  padding: 4px 12px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 22px;
  gap: 6px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.49px;
  color: var(--label-color);
  background: var(--label-bg-color);

  i,
  icon {
    font-size: 16px;
    color: var(--label-color);
  }

  &.label-sm {
    padding: 2px 8px;
    font-size: 12px;
  }

  &.label-light {
    background: var(--label-light-bg-color);
  }

  &.label-primary {
    --label-color: var(--tr-primary-color);
    --label-bg-color: rgba(18, 75, 186, 0.1);
  }

  &.label-accent {
    --label-color: var(--tr-accent-color);
    --label-bg-color: rgba(123, 97, 255, 0.1);
  }

  &.label-warn {
    --label-color: var(--tr-warn-color);
    --label-bg-color: rgba(255, 106, 106, 0.1);
  }

  &.label-green {
    --label-color: var(--tr-green);
    --label-bg-color: rgba(0, 203, 101, 0.1);
  }

  &.label-orange {
    --label-color: var(--tr-orange);
    --label-bg-color: rgba(246, 142, 66, 0.1);
  }

  &.label-magenta {
    --label-color: var(--tr-magenta);
    --label-bg-color: rgba(123, 97, 255, 0.1);
  }

  &.label-red {
    --label-color: var(--tr-red);
    --label-bg-color: rgba(255, 106, 106, 0.1);
  }

  &.label-disabled {
    --label-color: var(--tr-mad-gray);
    --label-bg-color: rgba(30, 29, 43, 0.1);
    opacity: 0.5;
  }
}
