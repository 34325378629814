@media print {
  h1,
  h2,
  h3 {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    margin: 0 !important;
  }

  h1 {
    font-size: 20px !important;
  }

  .no-print {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }

  tr-subheader {
    display: none !important;
  }

  .card {
    box-shadow: none !important;
  }

  .balance-sheet-body {
    width: 100% !important;
  }
  aside,
  .menu-collapse,
  .mat-toolbar .company-logo,
  .mat-toolbar .right-side {
    display: none !important;
  }

  main {
    padding: 1rem;
    margin-left: 0 !important;
    margin-top: 1rem !important;
  }

  .trigon-toolbar {
    .companies {
      font-size: 1rem;
      input {
        border: none !important;
        background: none !important;
        padding-left: 0 !important;
      }
    }
    height: 25px;
  }

  .report-row {
    .report-description {
      font-size: 14px !important;
    }
  }

  .print-d-none {
    display: none !important;
  }

  app-report-detail {
    .header {
      margin-bottom: 1rem !important;
    }
  }

  .actions {
    opacity: 0;
    height: 1px;
  }
}
