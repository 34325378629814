::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  border-radius: 0.25em;
}

::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 0.25em;
}

::-webkit-scrollbar-thumb:hover {
  background: #242424;
}
