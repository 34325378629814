@use 'src/assets/scss/utils/colors';

.pointer,
.cursor-pointer {
  cursor: pointer !important;
}

.pointer-default {
  cursor: default !important;
}

.pointer-none {
  cursor: pointer !important;
  pointer-events: none !important;
}

.t-align-end {
  text-align: end !important;
}

.t-align-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: colors.$tr-primary-color;
}

.text-secondary {
  color: colors.$tr-magenta;
}

.text-dark {
  color: colors.$tr-dark;
}

.text-gray {
  color: colors.$tr-gray;
}

.text-dark-gray {
  color: colors.$tr-dark-gray;
}

.text-light-gray {
  color: colors.$tr-light-gray;
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

.text-blue {
  color: colors.$tr-blue;
}

.text-green {
  color: colors.$tr-green;
}

.text-orange {
  color: colors.$tr-orange;
}

.text-red {
  color: colors.$tr-red;
}

.text-underline {
  text-decoration: underline;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-uppercase {
  text-transform: uppercase;
}

.vertical-center {
  vertical-align: center;
}

.link,
.link-like {
  cursor: pointer;
  color: colors.$tr-primary-color;
}

.link-disabled {
  cursor: not-allowed;
  color: colors.$tr-gray;
  pointer-events: none;
}

.capital {
  text-transform: capitalize;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-subtitle {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--tr-primary-color);
}

.first-letter-uppercase {
  &:first-letter {
    text-transform: uppercase !important;
  }
}

.text-success {
  color: var(--tr-green) !important;
}

.text-danger {
  color: var(--tr-red) !important;
}

.pre-wrap {
  white-space: pre-wrap;
}

.no-wrap {
  white-space: nowrap;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  letter-spacing: normal !important;
}

@for $size from 8 to 30 {
  .fs-#{$size} {
    font-size: #{$size}px !important;
  }
}
