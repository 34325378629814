.alert {
  padding: 0.5rem 1rem;
  color: white;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;

  &.alert-primary {
    color: var(--tr-primary-color);
    background-color: rgba(18, 75, 186, 0.1);
  }

  &.alert-accent {
    color: var(--tr-orange);
    background-color: rgba(246, 142, 66, 0.1);
  }

  &.alert-warn {
    color: var(--tr-red);
    background-color: rgba(255, 106, 106, 0.1);
  }
}
