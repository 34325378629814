// deprecated
.no-selection {
  color: rgb(170, 170, 170) !important;
}

.tr-select-search {
  .mat-mdc-option .mdc-list-item__primary-text {
    width: 100%;
  }
}

mat-option.mat-mdc-option {
  &.full-width-option {
    .mdc-list-item__primary-text {
      width: 100%;
    }
  }
}
