@mixin theme($theme-or-config) {
  mat-chip-grid,
  mat-chip-listbox {
    .mat-mdc-chip-action-label {
      white-space: pre;
    }
  }

  mat-chip-grid {
    &.inline-chip-list {
      .mat-mdc-chip.mat-mdc-standard-chip {
        height: 22px !important;
      }

      .mdc-evolution-chip-set__chips {
        display: flex;
        flex-wrap: initial;
        overflow-x: auto;

        .mdc-evolution-chip {
          margin: 0 4px;
        }
      }
    }
  }
}
