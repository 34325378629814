@use 'src/assets/scss/utils/mixins' as mixins;

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none !important;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.d-grid-center {
  display: grid;
  place-items: center;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.f-space-between {
  justify-content: space-between !important;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.f-end {
  justify-content: flex-end !important;
}

.f-start {
  justify-content: flex-start !important;
}

.f-center {
  justify-content: center !important;
}

.f-center-horizontal {
  align-items: center !important;
}

.f-start-horizontal {
  align-items: start !important;
}

.f-column {
  flex-direction: column;
}

.g-column-full {
  grid-column: 1/-1;
}

.col-2 {
  @include mixins.grid-column(2, 0);
}

.gap {
  gap: 1rem;
}

.haf-gap {
  gap: 0.5rem;
}

.gap-2x {
  gap: 2rem;
}

.gap-3x {
  gap: 3rem;
}

.gap-4x {
  gap: 4rem;
}

.bg-light-blue {
  background-color: var(--tr-gray-blue);
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.bg-white {
  background-color: var(--tr-white);
}

.bg-gray {
  background-color: var(--tr-gray);
}

.bg-dark-gray {
  background-color: var(--tr-dark-gray);
}

.bg-light-gray {
  background-color: var(--tr-light-gray);
}

.separator {
  margin: 2rem 0;
  border-bottom: 1px solid var(--tr-gray);
}

//grid columns
@for $i from 1 through 12 {
  .span-#{$i} {
    grid-column: span #{$i};
  }
}
