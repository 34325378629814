@use 'src/assets/scss/utils/mixins' as mixins;
.card {
  --tr-card-spacer-y: 1.5rem;
  --tr-card-spacer-x: 1.5rem;
  --tr-card-title-spacer-y: 0.5rem;
  --tr-card-border-width: 1px;
  --tr-card-border-radius: 0.25rem;
  --tr-card-inner-border-radius: calc(0.25rem - 1px);
  --tr-card-cap-padding-y: 0.5rem;
  --tr-card-cap-padding-x: 1rem;
  --tr-card-cap-bg: transparent;
  --tr-card-bg: white;
  --tr-card-img-overlay-padding: 1rem;
  --tr-card-group-margin: 0.75rem;

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--tr-card-bg);
  background-clip: border-box;
  border-radius: var(--tr-card-border-radius);
  padding: var(--tr-card-spacer-y) var(--tr-card-spacer-x);
  @include mixins.card-box-shadow;

  .card-header {
    margin-bottom: calc(var(--tr-card-cap-padding-y) * 2);
    margin-top: calc(var(--tr-card-spacer-y) * -1);
    margin-left: calc(var(--tr-card-spacer-x) * -1);
    margin-right: calc(var(--tr-card-spacer-x) * -1);
    padding: calc(var(--tr-card-cap-padding-y) * 3) calc(var(--tr-card-cap-padding-x) * 1.5);
    background-color: var(--tr-card-cap-bg);
    border-bottom: var(--tr-card-border-width) solid rgba(0, 0, 0, 0.125);
  }
}
