// deprecated
.no-selection {
  color: rgb(170, 170, 170) !important;
}

.tr-select-search {
  .mat-mdc-option .mdc-list-item__primary-text {
    width: 100%;
  }
}
