// badge style is deprecated
// and will be removed in the future
// use matBadge instead

@use '../utils/colors';

// @deprecated
.badge {
  margin-left: 0.5rem;
  align-self: center;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 65%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;

  &-primary {
    background: colors.$tr-blue;
    color: #fff;
  }

  &-success {
    color: white;
    background: colors.$tr-green;
  }

  &-info {
    color: white;
    background: colors.$tr-magenta;
  }

  &-warning {
    color: white;
    background: colors.$tr-orange;
  }

  &-danger {
    color: white;
    background: colors.$tr-red;
  }
}

.active-border {
  border-left-color: #40e6ff !important;
}
