.vertical-timeline {
  --line-width: 4px;
  --line-color: var(--tr-blue);
  --item-dot-size: 1.5rem;
  --item-dot-color: var(--tr-blue);
  --axes-centered-pos: 50%;
  --first-elem-size: calc(var(--axes-centered-pos) - (var(--item-dot-size) - var(--line-width)));

  height: auto;
  background: #fff;
  position: relative;

  &.left-aligned {
    --axes-centered-pos: 42%;
  }

  &.left-aligned-25 {
    --axes-centered-pos: 25%;
  }

  &.right-aligned {
    --axes-centered-pos: 58%;
  }

  .timeline-item {
    position: relative;
    height: 52px;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: var(--first-elem-size) 1fr;
    gap: calc(var(--item-dot-size) + 1rem);
    font-size: 14px;
    color: var(--tr-dark);

    & > * {
      flex: 1;
      &:first-child {
        text-align: right;
      }

      &:last-child {
        text-align: left;
      }
    }

    &.active {
      color: #fff;
      background-color: var(--tr-blue);
    }

    &::before {
      content: '';
      position: absolute;
      height: var(--item-dot-size);
      width: var(--item-dot-size);
      background-color: var(--item-dot-color);
      left: calc(var(--axes-centered-pos) - var(--item-dot-size) / 2);
      top: calc(50% - var(--item-dot-size) / 2);
      border-radius: 50%;
    }
  }

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: var(--line-width);
    background-color: var(--line-color);
    left: calc(var(--axes-centered-pos) - var(--line-width) / 2);
    top: 0;
  }
}
