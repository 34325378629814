@use '../../utils/colors';

.pending {
  color: rgba(0, 0, 0, 0.4);
}

.notifications {
  background: white;
  color: black;
}

mat-icon[svgIcon='upload'] {
  font-size: 16px;
  line-height: 16px;
  height: 16px;
  width: 16px;
  color: colors.$tr-blue;
}

.mat-mdc-tooltip {
  white-space: pre-line;
  font-size: 11pt;
  text-align: center;
}

.email-tooltip {
  white-space: pre-line;

  div {
    max-width: max-content;
  }
}

.mdc-card {
  padding: 16px;
}

.entry-data {
  .remove-btn {
    .mat-ripple {
      display: none;
    }
  }
}

.p-10 {
  padding-right: 10px;
}

.loading-message .mat-progress-spinner circle,
.mat-spinner circle {
  stroke: colors.$tr-blue;
}

.file-download {
  mat-icon {
    margin-left: 0.5rem;
  }
}

.empty {
  margin-top: 2rem;
  button {
    margin-left: 1rem;
  }
}

.remove-wrapper {
  min-width: 40px;
}

.description.ng-invalid {
  border: 1px solid colors.$tr-red !important;
}

.maintenance-overlay {
  background: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.costpocket-btn {
  &:hover .mat-mdc-button-persistent-ripple::before {
    opacity: 0;
  }
}
