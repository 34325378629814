@use 'sass:map';
@use '@angular/material' as mat;
@use '../utils/colors' as colors;
@use '../utils/sizes' as sizes;

$header-height: sizes.$tr-header-height;
$border-color: colors.$tr-border-color;
$border-size: sizes.$tr-header-border-width;

@mixin theme($theme-or-config) {
  .mat-toolbar:not(.mat-primary):not(.mat-accent):not(.mat-warn) {
    $palette: map.get($theme-or-config, primary);
    $contrast: mat.m2-get-contrast-color-from-palette($palette, 700);

    background-color: $contrast;
    border-bottom: $border-size solid $border-color;
    min-height: $header-height;
  }
}
