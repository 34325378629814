$tr-dark: var(--tr-dark);
$tr-blue: var(--tr-blue);
$tr-magenta: var(--tr-magenta);
$tr-green: var(--tr-green);
$tr-orange: var(--tr-orange);
$tr-red: var(--tr-red);
$tr-el-blue: var(--tr-el-blue);
$tr-gray-blue: var(--tr-gray-blue);
$tr-light-blue: var(--tr-light-blue);
$tr-gray: var(--tr-gray);
$tr-mad-gray: var(--tr-mad-gray);
$tr-light-gray: var(--tr-light-gray);
$tr-dark-gray: var(--tr-dark-gray);

$tr-primary-color: var(--tr-primary-color);
$tr-accent-color: var(--tr-accent-color);
$tr-warn-color: var(--tr-warn-color);

$tr-bg-color: var(--tr-bg-color);
$tr-border-color: var(--tr-border-color);

$tr-primary-palette: (
  50: #e3eaf7,
  100: #b8c9ea,
  200: #89a6dd,
  300: #5982cf,
  400: #3667c4,
  500: #124cba,
  600: #1045b3,
  700: #0d3cab,
  800: #0a33a3,
  900: #052494,
  A100: #c0caff,
  A200: #8d9fff,
  A400: #5a74ff,
  A700: #415eff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$tr-accent-palette: (
  50: #efecff,
  100: #d7d0ff,
  200: #bdb0ff,
  300: #a390ff,
  400: #8f79ff,
  500: #7b61ff,
  600: #7359ff,
  700: #684fff,
  800: #5e45ff,
  900: #4b33ff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #e1deff,
  A700: #cbc4ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$tr-warn-palette: (
  50: #ffeded,
  100: #ffd2d2,
  200: #ffb5b5,
  300: #ff9797,
  400: #ff8080,
  500: #ff6a6a,
  600: #ff6262,
  700: #ff5757,
  800: #ff4d4d,
  900: #ff3c3c,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffe5e5,
  A700: #ffcbcb,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// aliases
$trigon-dark: $tr-dark;
$trigon-blue: $tr-blue;
$trigon-magenta: $tr-magenta;
$trigon-green: $tr-green;
$trigon-orange: $tr-orange;
$trigon-red: $tr-red;
$trigon-el-blue: $tr-el-blue;
$trigon-light-blue: $tr-light-blue;
$trigon-gray-blue: $tr-gray-blue;
$trigon-gray: $tr-gray;
$trigon-mad-gray: $tr-mad-gray;
$trigon-light-gray: $tr-light-gray;
$trigon-dark-gray: $tr-dark-gray;
