@use '../utils/mixins' as mixins;
@use 'src/assets/icons/trigon-icons/style';

.tr {
  font-size: 18px;

  &.icon-xs {
    font-size: 12px;
  }

  &.icon-sm {
    font-size: 16px;
  }

  &.icon-md {
    font-size: 20px;
  }

  &.icon-lg {
    font-size: 24px;
  }

  &.icon-xl {
    font-size: 32px;
  }

  &.icon-spin {
    @include mixins.spin;
  }

  &.icon-rotate-90 {
    transform: rotate(90deg);
    transform-origin: center;
  }

  &.icon-rotate-180 {
    transform: rotate(180deg);
    transform-origin: center;
  }

  &.icon-rotate-270 {
    transform: rotate(270deg);
    transform-origin: center;
  }

  &.icon-top-2 {
    top: -2px;
    position: relative;
  }
}
