:root {
  --tr-dark: #1e1d2b;
  --tr-blue: #124bba;
  --tr-white: #ffffff;
  --tr-green: #00cb65;
  --tr-orange: #f68e42;
  --tr-light-orange: #fbcba5;
  --tr-magenta: #7b61ff;
  --tr-red: #ff6a6a;
  --tr-el-blue: #40e6ff;
  --tr-light-blue: #e8edf8;
  --tr-azure-white: #d1dbf2;
  --tr-gray-blue: #e6edf8;
  --tr-gray: #e1e1e1;
  --tr-light-gray: #f9f9f9;
  --tr-white-smoke: #f1f1f1;
  --tr-mad-gray: #646464;
  --tr-dark-gray: #56575e;
  --tr-light-body-bg: #fafafa;
  --tr-dark-body-bg: #303030;

  --tr-body-bg: var(--tr-light-body-bg);
  --tr-primary-color: var(--tr-blue);
  --tr-primary-white: var(--tr-azure-white);
  --tr-accent-color: var(--tr-magenta);
  --tr-warn-color: var(--tr-red);

  --tr-bg-color: var(--tr-light-gray);
  --tr-border-color: var(--tr-light-gray);
  --tr-subheader-bg-color: var(--tr-white);

  --tr-header-height: 64px;
  --tr-header-border-width: 2px;
  --tr-sidebar-width: 230px;
  --tr-sidebar-minimized-width: 70px;
  --tr-sidebar-background-color: var(--tr-blue);
  --tr-sidebar-foreground-color: var(--tr-white);
  --tr-content-padding: 32px;

  --tr-soft-box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.03);
  --tr-card-box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.06);

  --tr-field-height: 46px;
  --tr-field-padding: 16px;
  --tr-field-border-radius: 4px;
  --tr-field-border-color: var(--tr-gray);

  --tr-animation-duration: 625ms;
  --tr-easing: cubic-bezier(0.25, 0.8, 0.25, 1);
}
