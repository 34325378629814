@use '../../utils/colors';

// sidebar styles
aside {
  .mat-mdc-list-base .mat-mdc-list-item {
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    .mdc-list-item__primary-text {
      color: var(--tr-sidebar-foreground-color);
    }

    border-left: 2px solid transparent;
    transition: border-left-color ease-in-out;
  }

  .list-unstyled {
    padding-top: 12px;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    list-style: none;
    will-change: padding;
    transition: 0.25s all ease-in-out;

    &.opened {
      padding-top: 10px;
    }

    li {
      min-height: 56px;
      display: flex;
      align-items: center;

      button.heading {
        padding: 1px 24px;
      }

      &:focus {
        outline: none;
        background: rgba(0, 0, 0, 0.08);
      }

      &.menu-logo-mini {
        min-height: 0;
        height: 0;
        opacity: 0;
        transition: all 0.3s ease-in;
      }
    }
  }

  app-trigon-accordion-item {
    .mat-mdc-list-base .mat-mdc-list-item .mdc-list-item__content,
    .mat-list-base .mat-list-option .mdc-list-item__content {
      padding: 0 18px;
    }
  }
}
