// @deprecated
.loading-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// @deprecated
// loading styles
.global-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay-loading {
  --loader-indicator-size: 60px;
  --loader-indicator-margin: calc(var(--loader-indicator-size) / -2);

  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.85);
  }

  &:after {
    content: '';
    z-index: 2;
    position: absolute;
    left: 50%;
    top: 50%;
    width: var(--loader-indicator-size);
    height: var(--loader-indicator-size);
    margin: var(--loader-indicator-margin) 0 0 var(--loader-indicator-margin);
    border-radius: 50%;
    border: 2px solid var(--tr-gray);
    border-top-color: var(--tr-primary-color);
    animation: spin 1s cubic-bezier(0.13, 0.4, 0.73, 0.53) infinite;
  }
}
