.nav-text {
  margin-left: 20px;
  font-size: 1rem;
  opacity: 1;
  transition: 0.2s ease-in-out;
  white-space: nowrap;
}

nav.sub-nav {
  display: flex;
  border-bottom: solid 2px var(--tr-light-blue);

  .nav-item {
    outline: none;
    cursor: pointer;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
      color 0.2s ease,
      border-color 0.2s ease;
    margin-bottom: -2px;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 0.19px;
    border-bottom: 2px solid transparent;
    color: gray;

    &.active {
      color: var(--tr-dark);
      border-bottom-color: var(--tr-primary-color);
    }
  }
}
