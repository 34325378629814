@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?i33ibm');
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?i33ibm#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?i33ibm') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?i33ibm') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?i33ibm##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.tr {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-trigon-logo {
  &:before {
    content: $icon-trigon-logo;
  }
}
.icon-costpocket {
  &:before {
    content: $icon-costpocket;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}
.icon-sales {
  &:before {
    content: $icon-sales;
  }
}
.icon-expenses {
  &:before {
    content: $icon-expenses;
  }
}
.icon-accounting {
  &:before {
    content: $icon-accounting;
  }
}
.icon-shopping-card {
  &:before {
    content: $icon-shopping-card;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-bureaus {
  &:before {
    content: $icon-bureaus;
  }
}
.icon-document {
  &:before {
    content: $icon-document;
  }
}
.icon-reports {
  &:before {
    content: $icon-reports;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-list-items {
  &:before {
    content: $icon-list-items;
  }
}
.icon-car {
  &:before {
    content: $icon-car;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-lock-open {
  &:before {
    content: $icon-lock-open;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-transfer {
  &:before {
    content: $icon-transfer;
  }
}
.icon-visibility {
  &:before {
    content: $icon-visibility;
  }
}
.icon-grid {
  &:before {
    content: $icon-grid;
  }
}
.icon-rotate {
  &:before {
    content: $icon-rotate;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-add {
  &:before {
    content: $icon-add;
  }
}
.icon-plus-big {
  &:before {
    content: $icon-plus-big;
  }
}
.icon-caret-circle {
  &:before {
    content: $icon-caret-circle;
  }
}
.icon-caret-down {
  &:before {
    content: $icon-caret-down;
  }
}
.icon-caret-up {
  &:before {
    content: $icon-caret-up;
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left;
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-bottom {
  &:before {
    content: $icon-arrow-bottom;
  }
}
.icon-bookmark-filled {
  &:before {
    content: $icon-bookmark-filled;
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark;
  }
}
.icon-tools {
  &:before {
    content: $icon-tools;
  }
}
.icon-reload {
  &:before {
    content: $icon-reload;
  }
}
.icon-checkbox {
  &:before {
    content: $icon-checkbox;
  }
}
.icon-checkbox-unchecked {
  &:before {
    content: $icon-checkbox-unchecked;
  }
}
.icon-checkbox-indeterminate {
  &:before {
    content: $icon-checkbox-indeterminate;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle;
  }
}
.icon-check-circle-outline {
  &:before {
    content: $icon-check-circle-outline;
  }
}
.icon-close-circle {
  &:before {
    content: $icon-close-circle;
  }
}
.icon-duplicate {
  &:before {
    content: $icon-duplicate;
  }
}
.icon-create {
  &:before {
    content: $icon-create;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-filter-filled {
  &:before {
    content: $icon-filter-filled;
  }
}
.icon-attachment {
  &:before {
    content: $icon-attachment;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-error {
  &:before {
    content: $icon-error;
  }
}
.icon-error-outline {
  &:before {
    content: $icon-error-outline;
  }
}
.icon-round-report {
  &:before {
    content: $icon-round-report;
  }
}
.icon-actions {
  &:before {
    content: $icon-actions;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-filter-list {
  &:before {
    content: $icon-filter-list;
  }
}
.icon-dots-horizontal {
  &:before {
    content: $icon-dots-horizontal;
  }
}
.icon-dots-vertical {
  &:before {
    content: $icon-dots-vertical;
  }
}
.icon-fullscreen {
  &:before {
    content: $icon-fullscreen;
  }
}
.icon-fullscreen-exit {
  &:before {
    content: $icon-fullscreen-exit;
  }
}
.icon-minus-circle {
  &:before {
    content: $icon-minus-circle;
  }
}
.icon-notification-important {
  &:before {
    content: $icon-notification-important;
  }
}
.icon-notifications-filled {
  &:before {
    content: $icon-notifications-filled;
  }
}
.icon-notifications {
  &:before {
    content: $icon-notifications;
  }
}
.icon-radio-checked {
  &:before {
    content: $icon-radio-checked;
  }
}
.icon-radio-unchecked {
  &:before {
    content: $icon-radio-unchecked;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-star-empty {
  &:before {
    content: $icon-star-empty;
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half;
  }
}
.icon-favorite {
  &:before {
    content: $icon-favorite;
  }
}
.icon-transactions-2 {
  &:before {
    content: $icon-transactions-2;
  }
}
.icon-upload-to-cloud {
  &:before {
    content: $icon-upload-to-cloud;
  }
}
.icon-user-transfer {
  &:before {
    content: $icon-user-transfer;
  }
}
.icon-user-group {
  &:before {
    content: $icon-user-group;
  }
}
.icon-pop-out {
  &:before {
    content: $icon-pop-out;
  }
}
.icon-place {
  &:before {
    content: $icon-place;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-dns {
  &:before {
    content: $icon-dns;
  }
}
.icon-rule {
  &:before {
    content: $icon-rule;
  }
}
.icon-account_tree {
  &:before {
    content: $icon-account_tree;
  }
}
.icon-euro {
  &:before {
    content: $icon-euro;
  }
}
.icon-exit {
  &:before {
    content: $icon-exit;
  }
}
.icon-group-people {
  &:before {
    content: $icon-group-people;
  }
}
.icon-local-mall {
  &:before {
    content: $icon-local-mall;
  }
}
.icon-globe {
  &:before {
    content: $icon-globe;
  }
}
.icon-pause-circle {
  &:before {
    content: $icon-pause-circle;
  }
}
.icon-reload-circle {
  &:before {
    content: $icon-reload-circle;
  }
}
.icon-annual-reports {
  &:before {
    content: $icon-annual-reports;
  }
}
.icon-e-invoice {
  &:before {
    content: $icon-e-invoice;
  }
}
.icon-uku {
  &:before {
    content: $icon-uku;
  }
}
