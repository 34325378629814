// all table styles are deprecated
// and will be rewritten by angular material style guide.

@use 'sass:map';
@use '../utils/colors';

table {
  width: 100%;
}

th,
td {
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// @deprecated
tr.mat-mdc-footer-row {
  font-weight: bold;
}

// @deprecated
td.mat-column-actions {
  width: 20px;
  padding-right: 8px;
}

// @deprecated
.mat-mdc-cell,
.mat-mdc-header-cell {
  padding: 0 10px;
}

// @deprecated
.mat-mdc-cell:first-of-type {
  padding-left: 24px;
}

// @deprecated
.mat-mdc-cell:last-of-type {
  padding-right: 24px;
}

// @deprecated
.mat-mdc-header-cell:first-of-type {
  padding-left: 24px;
}

// @deprecated
.mat-mdc-header-cell:last-of-type {
  padding-right: 24px;
}

.empty-table {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 80px;
  color: colors.$tr-gray;
  font-size: 20px;
  font-variant: small-caps;
  background: #fff;

  h3 {
    margin-bottom: 0;
  }
}

.trigon-blue-header {
  th {
    color: colors.$tr-blue;
    font-weight: 500;
  }
}

.mat-column-actions {
  flex: 0 1 74px;
}

.nav-row {
  cursor: pointer;
  transition: background-color 0.2s;
  .mat-mdc-table-sticky {
    background-color: unset;
  }
  &:hover:not(:active) {
    background-color: rgba(0, 0, 0, 0.03);
  }
}

.totals-row {
  td {
    font-weight: 500;
    background-color: colors.$tr-light-blue;
  }
}
