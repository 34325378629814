@use '@angular/material' as mat;
@use '../utils/colors';

@mixin theme($theme-or-config) {
  :root {
    --mat-form-field-subscript-text-size: 12px;
    --mdc-outlined-text-field-label-text-size: 14px;
    --mat-form-field-container-text-size: 14px;
    --mat-select-trigger-text-size: 14px;
  }

  $default-density: -2;
  $small-density: -5;

  @include mat.select-density($default-density);
  @include mat.input-density($default-density);
  @include mat.form-field-density($default-density);

  mat-form-field {
    &.field-sm {
      @include mat.select-density($small-density);
      @include mat.input-density($small-density);
      @include mat.form-field-density($small-density);
    }

    &.readonly {
      pointer-events: none;

      .mat-mdc-text-field-wrapper {
        background-color: colors.$tr-light-gray;
      }
    }

    &.no-subscript {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    &.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
      padding-left: 10px;
    }

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
        top: 22px;
      }
    }

    &.wrong-credentials {
      .mdc-text-field--outlined {
        color: colors.$tr-red;
      }
      input {
        caret-color: colors.$tr-red;
      }
    }

    &.ng-warning {
      --mdc-outlined-text-field-outline-color: colors.$tr-orange;
      --mdc-outlined-text-field-hover-outline-color: colors.$tr-orange;
      --mdc-outlined-text-field-label-text-color: colors.$tr-orange;
      --mdc-outlined-text-field-hover-label-text-color: colors.$tr-orange;

      .mdc-text-field--outlined {
        color: colors.$tr-orange;
      }
      input {
        caret-color: colors.$tr-orange;
      }

      .mat-mdc-form-field-subscript-wrapper {
        color: colors.$tr-orange;
      }
    }

    .loader-suffix {
      position: absolute;
      top: 0;
      bottom: 0;
      right: -1rem;
      display: flex;
      align-items: center;
      padding-right: 8px;

      .mat-mdc-progress-spinner {
        circle {
          stroke: var(--tr-mad-gray);
        }
      }
    }
  }
}
