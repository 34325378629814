@mixin font-awesome($font-family, $content, $weight: 900) {
  content: $content;
  font-family: $font-family;
  font-weight: $weight;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

@mixin ralevay-font {
  font-family: 'Raleway', sans-serif;
}

@mixin sticky-button {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

@mixin box-shadow {
  box-shadow:
    8px 14px 38px rgba(39, 44, 49, 0.06),
    1px 3px 8px rgba(39, 44, 49, 0.03);
}

@mixin card-box-shadow {
  box-shadow: var(--tr-card-box-shadow);
}

@mixin soft-box-shadow {
  box-shadow: var(--tr-soft-box-shadow);
}

@mixin border($border-dir, $border-color: black) {
  #{$border-dir}: 1px solid $border-color;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin space-between {
  display: flex;
  justify-content: space-between;
}

@mixin grid-column($count, $minWidth) {
  grid-template-columns: repeat($count, minmax($minWidth, 1fr));
}

@mixin spin($animation-duration: 2s) {
  animation: spin $animation-duration linear infinite;
}

@mixin pulse($animation-duration: 1.5s, $animation-scale-factor: 1.2) {
  animation: pulse $animation-duration infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    //transform: scale(1);
    opacity: 1;
  }
  50% {
    //transform: scale($animation-scale-factor);
    opacity: 0.6;
  }
  100% {
    //transform: scale(1);
    opacity: 1;
  }
}
